import { getStore } from '../store/Store';
import {
    getIsBitSet,
    type ListViewBitFlagsMasks,
} from 'owa-bit-flags/lib/utils/listViewBitFlagsUtil';
import type { TableView } from 'owa-mail-list-store';
import { isConversationView } from 'owa-mail-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getListViewColumnWidths(tableView?: TableView): {
    senderColumnWidth: number;
    subjectColumnWidth: number;
    receivedColumnWidth: number;
} {
    let senderColumnWidth = getStore().senderColumnWidth || 332;
    let subjectColumnWidth = getStore().subjectColumnWidth || 1000;
    let receivedColumnWidth = getStore().receivedColumnWidth || 150;

    const isSenderImageOff = getIsBitSet(8, getGlobalSettingsAccountMailboxInfo());
    const _isConversationView = tableView && isConversationView(tableView);

    if (_isConversationView && !isSenderImageOff) {
        senderColumnWidth += 68;
    }

    if (_isConversationView && isSenderImageOff) {
        senderColumnWidth += 61;
    }

    if (!_isConversationView && !isSenderImageOff) {
        senderColumnWidth += 48;
    }

    if (!_isConversationView && isSenderImageOff) {
        senderColumnWidth += 52;
    }

    // Remove 6 pixels for column spacing
    subjectColumnWidth -= 6;

    // Remove 44px for big hover action spacing
    receivedColumnWidth -= 44;

    return {
        senderColumnWidth,
        subjectColumnWidth,
        receivedColumnWidth,
    };
}
